/* === Directus Module Names === */

export enum DirectusModule {
  Fraction = 'fraction',
  Container = 'container',
  Review = 'cde_review',
  Header = 'cde_header',
  LandingPage = 'landing_page',
  Page = 'page',
  Footer = 'cde_footer',
  Content = 'content',
  Hero = 'hero',
  OrderNow = 'order_now',
  Faq = 'faq',
  ReviewWrapper = 'review_wrapper',
  Text = 'text',
  TextListCard = 'text_listcard',
  TextCTA = 'text_cta',
  TextPicture = 'text_picture',
  Contact = 'contact',
  Infobox = 'infobox',
  CustomTable = 'custom_table',
  WasteRule = 'waste_rule',
  SizeWrapper = 'size_wrapper',
  Cards = 'cards',
  SolutionCard = 'solution_card',
  PrivacyPolicyPage = 'privacy_policy',
  Imprint = 'imprint',
  AGB = 'agb',
}

/* === Directus Enum Values in Modules === */

export enum PageStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export enum PageType {
  Abfallunterseite = 'waste',
  Stadtseite = 'city',
  B2bSeite = 'b2b',
}

export enum ContentBackground {
  White = 'white',
  TwoGradient = 'gradient',
  ThreeGradient = 'three_gradient',
}

export enum HeroType {
  Abfallunterseite = 'waste',
  Stadtseite = 'general',
  B2bSeite = 'b2b',
}

export enum BoxOrPicturePosition {
  Left = 'left',
  Right = 'right',
}

export enum ListBoxType {
  WithNumber = 'number',
  WithBulletPoints = 'point',
}

export enum CTAType {
  InfoAndButton = 'plain',
  ZipAndWaste = 'zip',
}

export enum CloudDecoration {
  None = 'none',
  CornerOfTheBox = 'corner',
  HalfOutside = 'outside',
}

export enum TableType {
  TwoColumnsWithMergedHeader = 'two',
  FourColumnsIndividualHeaders = 'four',
}

export enum CardsType {
  Waste = 'waste',
  Solution = 'solution',
  Benefit = 'benefit',
}

export enum CardIcon {
  Person = 'person',
  Phone = 'phone',
  Location = 'location',
  None = 'none',
}

export enum ListCardTitleIcon {
  None = 'none',
  Info = 'info',
}

export enum OrderNowType {
  ZipAndWaste = 'zip',
  ButtonOnly = 'button',
}

/* === Directus Enum Module Filters === */
export const contentFieldsFilter = [
  '*.*',
  '*.item.*',
  ' *.collection',
  '*.item.waste_card_items.waste_card_id.*',
  '*.item.benefit_card_items.benefit_card_id.*',
  '*.item.solution_card_items.solution_card_id.*',
  '*.item.translations.*', //for showing translation in any subcontent items
  '*.item.waste_card_items.waste_card_id.translations.*',
  '*.item.benefit_card_items.benefit_card_id.translations.*',
  '*.item.solution_card_items.solution_card_id.translations.*',
  '*.item.fraction.*',
  '*.item.containers.*',
  '*.item.fraction.containers.*',
  '*.item.translations.*',
  '*.item.displayed_reviews.*.*',
] as string[]
export const pageFieldsFilter = ['*', '*.*'] as string[]
export const LandingPageFieldsFilter = [
  '*',
  'contents.*.landing_page_content.*',
  'card_module.*.*.*',
  'order_now_content.*',
] as string[]
export const fractionFieldsFilter = ['*', 'containers.*', 'sidebar_images.*'] as string[]

export enum CONTAINER_DELIVERY_TIME_FRAME {
  WHOLE_DAY = 'Ganztägig 7-17 Uhr',
  MORNING = 'Vormittag 7-12 Uhr',
  AFTERNOON = 'Nachmittag 12-17 Uhr',
}

export enum ACTIVE_SIDEBAR_TYPE {
  ZIP = 'ZIP',
  FRACTION = 'FRACTION',
  CONTAINER = 'CONTAINER',
  DATE = 'DATE',
  SPACE_REQUIREMENT = 'SPACE_REQUIREMENT',
  RULES = 'RULES',
  CART = 'CART',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DateStringOptions: any = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  timezone: 'Europe/Berlin',
}
export enum SIDEBAR_STATE {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum PAYMENT_METHOD {
  EPD_STRIPE_PAYPAL = 11,
  EPD_STRIPE_CARD = 12,
}

export enum EMPTO_ORDER_TYPE {
  ONE_TIME = 1,
}

export enum EMPTO_DAY {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
}
export enum GENDER {
  MR = 1,
  MS = 2,
}

export enum EMPTO_CUSTOMER_TYPE {
  PRIVATE = 1,
  COMPANY = 2,
}

export enum EMPTO_DELIVERY_TIME_FRAME {
  MORNING = 1,
  AFTERNOON = 2,
  WHOLE_DAY = 3,
}

export enum CartApiAction {
  create = 'create',
  accept = 'accept',
}

export enum DISCOUNT_STATUS {
  OK = 1,
  EXPIRED = 2,
  BLOCKED = 3,
}

export const cityList = [
  'aachen',
  'augsburg',
  'bergisch-gladbach',
  'berlin',
  'bielefeld',
  'bochum',
  'bonn',
  'bottrop',
  'braunschweig',
  'bremen',
  'chemnitz',
  'darmstadt',
  'dortmund',
  'dresden',
  'duesseldorf',
  'duisburg',
  'erfurt',
  'essen-ruhr',
  'frankfurt-am-main',
  'freiburg-im-breisgau',
  'fuerth-bayern',
  'gelsenkirchen',
  'goettingen',
  'hagen-westfalen',
  'halle-saale',
  'hamburg',
  'hamm-am-rhein',
  'hannover',
  'heidelberg',
  'heilbronn-neckar',
  'herne-westfalen',
  'ingolstadt-donau',
  'karlsruhe',
  'kassel-hessen',
  'kiel',
  'koblenz-am-rhein',
  'koeln',
  'krefeld',
  'leipzig',
  'leverkusen',
  'ludwigshafen-am-rhein',
  'luebeck',
  'magdeburg',
  'mainz',
  'mannheim',
  'moenchengladbach',
  'muelheim-an-der-ruhr',
  'muenchen',
  'muenster-westfalen',
  'neuss',
  'nuernberg',
  'oberhausen',
  'offenbach-am-main',
  'oldenburg-in-oldenburg',
  'osnabrueck',
  'paderborn',
  'pforzheim',
  'potsdam',
  'recklinghausen',
  'regensburg',
  'reutlingen',
  'rostock',
  'saarbruecken',
  'solingen',
  'stuttgart',
  'ulm-donau',
  'wiesbaden',
  'wolfsburg',
  'wuerzburg',
  'wuppertal',
] as string[]
